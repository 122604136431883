import React, { useState, useEffect, Fragment, useMemo, InputHTMLAttributes, forwardRef, useCallback } from 'react';
import { Button, Row, Col, Card, CardBody, Label, FormFeedback, Input, InputGroup, Nav, UncontrolledTooltip } from 'reactstrap';
import Select, { SingleValue } from 'react-select';
import classnames from "classnames";
import { useDispatch, useSelector } from 'react-redux';
import { ChevronLeft, ChevronRight, Download, File, FileText, LogIn, Trash2, X, } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import webservice from '../../../Service/webservice';
import { AesDecrypt, AesEncrypt } from '../../../Service/crypto';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css'; // Import the CSS for the theme you want to use
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver'
import { useAppDispatch } from '../../../ReaduxToolkit/Hooks';
import ReactPaginate from 'react-paginate';
import { getManualData } from './store';

interface DeleteRowData {
    Patientname: string;
    id: string;
    PatientDOB: string;
    Memberid: string;
    client_name: string;
    uploaded_date: string;
    RefId: string;
    Payor_ID: string;
    Provider_NPI: string;
    Payer_ID: string;
    Provider_Name: string;
    Provider_ID: string;
    Payer_Name: string;
    Data_Fetched: string;
    xml_conversion: string;
    error_message: string;
    // uploaded_date: string;
    template: string;
    FeeSchedule: string;
    regen_status: string;
}

interface IDefaultValues {
    DoB: string;
    firstName: string;
    MiddleName?: string;
    lastName: string;
    Memberid: string;
    // PayorID: string;
    ProviderNPI: string;
    ClientName: string;
    Insurancename: string;
    Subscriber: string;
    Address: string;
    FeeSchedule: string;
}

interface Iinsuranceoption {
    label: string;
    value: string;
}

interface Addresseoption {
    label: string;
    value: string;
}

const defaultValues: IDefaultValues = {
    DoB: '',
    firstName: '',
    MiddleName: '',
    lastName: '',
    Memberid: '',
    // PayorID: '',
    ProviderNPI: '',
    ClientName: '',
    Insurancename: '',
    Subscriber: '',
    Address: '',
    FeeSchedule: "",
};

interface IClientOption {
    label: string;
    value: string;
}

interface IOption {
    value: string;
    label: string;
}

const SubscriberOptions: IOption[] = [
    { value: 'subscriber', label: 'subscriber' },
    { value: 'dependent', label: 'Dependent' },
];

const CustomHeader = ({ handleFilter, value, handlePerPage, rowsPerPage }: any) => {
    return (
        <div className='client-master-list-table-header w-100 py-2'>
            <Row>
                <Col lg='6' className='d-flex align-items-center px-0 px-lg-1'>
                    <div className='d-flex align-items-center me-4 '>
                        <label htmlFor='rows-per-page' className='me-2'>Rows per Page:</label>
                        <Input
                            type='select'
                            id='rows-per-page'
                            value={rowsPerPage}
                            onChange={handlePerPage}
                            className='form-control ms-50 larger-select '
                        >
                            <option value='10'>10</option>
                            <option value='25'>25</option>
                            <option value='50'>50</option>
                        </Input>
                    </div>
                </Col>
                <Col
                    lg='6'
                    className='d-flex align-items-center justify-content-lg-end mt-lg-0 mt-1 px-0 px-lg-3'
                >
                    <InputGroup className="input-group-search mb-2 zoom-on-hover">
                        <Input
                            type="text"
                            placeholder="🔍 Search...."
                            value={value}
                            onChange={(e) => handleFilter(e.target.value)}
                            className="input-search"
                        />
                    </InputGroup>
                </Col>
            </Row>
        </div>
    );
};

const Bulkupload: React.FC = () => {
    const { control, handleSubmit, formState: { errors }, reset } = useForm<IDefaultValues>({
        defaultValues,
    });

    const [selectedClientId, setSelectedClientId] = useState<string>('');
    const [selectedClient, setSelectedClient] = useState<string>('');
    const [selectedtemplate, setselectedtemplate] = useState<string>('');
    const [selectedtemplateId, setselectedtemplateId] = useState<string>('');
    const [SelectedInsurancename, setSelectedInsurance] = useState<string>('');
    const [SelectedInsuranceId, setSelectedInsuranceId] = useState<string>('');
    const [SelectedAddressname, setSelectedAddress] = useState<string>('');
    const [SelectedAddressId, setSelectedAddressId] = useState<string>('');
    const Username = localStorage.UserName;
    const [UserType] = useState<string>(localStorage.UserType);
    const [Id, setId] = useState<string>('');
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isOpenpatient, setIsOpenpatient] = useState<boolean>(false);
    const toggle = () => setIsOpen(!isOpen);
    const togglepatient = () => setIsOpenpatient(!isOpenpatient);
    const navigate = useNavigate();

    useEffect(() => {
        if (UserType !== 'MOS' && UserType !== 'Vendor') {
            setSelectedClient(localStorage.getItem('ClientName') || '');
            setSelectedClientId(localStorage.getItem('ClientId') || '');
        }
    }, [UserType]);
    useEffect(() => {
        if (UserType === 'Vendor') {
            setId(localStorage.getItem('VendorId') || '');
        } else
            if (UserType === 'Client') {
                setId(localStorage.getItem('ClientId') || '');
            }
            else if (UserType === 'MOS') {
                setId(localStorage.getItem('id') || '');
            }
    }, [UserType]);


    const [clientOptions, setClientOptions] = useState<IClientOption[]>([]);
    const [vendorbasedclientOptions, setvendorbasedclientOptions] = useState<IClientOption[]>([]);

    const [templateOptions, settemplateOptions] = useState<IClientOption[]>([]);
    const [insuranceoption, setInsurance] = useState<[]>([]);
    const [Addresseoption, setAddress] = useState<[]>([]);
    const [eventId, setEventId] = useState<DeleteRowData[]>([]);
    const [eventId1, setEventId1] = useState(0);
    const selectedRowsPerPage: { [key: number]: DeleteRowData[] } = useMemo(() => ({}), []);
    const menuActions = ['add', 'delete'];
    const action = { fromUser: true };
    const dispatch = useAppDispatch()
    const store = useSelector((state: any) => state.ManualEntries)
    const [value, setValue] = useState<string>('');
    const [sort, setSort] = useState<string>('');
    const [sortColumn, setSortColumn] = useState<any>('uploaded_date'); // Example initial sort column
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);



    useEffect(() => {
        if (UserType && Id) {
            dispatch(
                getManualData({
                    Id,
                    UserType,
                    q: value,
                    perPage: rowsPerPage,
                    page: currentPage,
                    sort,
                    sortColumn
                })
            );
        }
    }, [dispatch, store.data.length, UserType, Id]);

    const handleReloadTable = () => {
        dispatch(
            getManualData({
                Id,
                UserType,
                q: value,
                perPage: rowsPerPage,
                page: currentPage,
                sort,
                sortColumn
            })
        );
    };


    const handleFilter = (val: any) => {
        setValue(val)
        dispatch(
            getManualData({
                Id,
                UserType,
                q: val,
                perPage: rowsPerPage,
                page: currentPage,
                sort,
                sortColumn,
            })
        )
    }

    const handlePerPage = (e: any) => {
        setCurrentPage(1)
        dispatch(
            getManualData({
                Id,
                UserType,
                q: value,
                perPage: parseInt(e.target.value),
                page: currentPage,
                sort,
                sortColumn,
            })
        )
        setRowsPerPage(parseInt(e.target.value))
    }



    const handlePagination = (page: any) => {
        dispatch(
            getManualData({
                Id,
                UserType,
                q: value,
                perPage: rowsPerPage,
                page: page.selected + 1,
                sort,
                sortColumn,
            })
        )
        setCurrentPage(page.selected + 1)
    }


    const dataToRender = () => {
        const filters: any = {
            q: value

        }
        const isFiltered = Object.keys(filters).some(function (k) {
            return filters[k].length > 0
        })

        if (store.data.length > 0) {
            return store.data
        } else if (store.data.length === 0 && isFiltered) {
            return []
        } else {
            return store.allData.slice(0, rowsPerPage)
        }
    }

    const handleSort = (column: any, sortDirection: any) => {
        setSort(sortDirection)
        setSortColumn(column.sortField)
        dispatch(
            getManualData({
                Id,
                UserType,
                q: value,
                perPage: rowsPerPage,
                page: currentPage,
                sort: sortDirection,
                sortColumn: column.sortField
            })
        )
    }

    const CustomPagination = () => {
        const count = Number(Math.ceil(store.total / rowsPerPage))

        return (
            <ReactPaginate
                previousLabel={<ChevronLeft size={16} />}
                nextLabel={<ChevronRight size={16} />}
                breakLabel="..."
                pageCount={count || 1}
                onPageChange={handlePagination}
                forcePage={currentPage !== 0 ? currentPage - 1 : 0}
                containerClassName="pagination-container"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                activeClassName="active"
            />
        )
    }

    const get_companyname = async () => {
        const response = await fetch(webservice + 'api/BulkUpload/companyname', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        }).then((response) => response.json());

        const TempData = AesDecrypt(response);
        return TempData;
    };

    useEffect(() => {
        const fetchRoleId = async () => {
            const response = await get_companyname();
            const res = response.map((data: any) => ({
                label: data.CompanyName,
                value: data.id,
            }));
            setClientOptions(res);
        };

        fetchRoleId();
    }, [dispatch]);

    const get_companynamevendorbased = async () => {
        const EncParams = AesEncrypt(JSON.stringify({ id: localStorage.VendorId }));

        const response = await fetch(webservice + 'api/BulkUpload/vendorbasedclientOptions', {
            method: 'POST',
            body: JSON.stringify({ EncParams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        }).then((response) => response.json());

        const TempData = AesDecrypt(response);
        return TempData;
    };
    useEffect(() => {
        const vendorbasedfetchRoleId = async () => {
            const response = await get_companynamevendorbased();

            const res = response.map((data: any) => ({
                label: data.CompanyName,
                value: data.id,
            }));
            setvendorbasedclientOptions(res);
        };

        vendorbasedfetchRoleId();
    }, []);




    const get_Insurance = async () => {
        const response = await fetch(webservice + 'api/insurancename', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        }).then((response) => response.json());

        const TempData = AesDecrypt(response);
        return TempData;
    };

    useEffect(() => {
        const fetchRoleId = async () => {
            const response = await get_Insurance();
            const res = response.map((data: any) => ({
                label: data.name,
                value: data.name,
            }));
            setInsurance(res);
        };

        fetchRoleId();
    }, [dispatch]);


    const handleSelectClientChange = (selectedOption: SingleValue<IClientOption>) => {
        if (selectedOption) {
            setSelectedClient(selectedOption.label);
            setSelectedClientId(selectedOption.value);
            // template()
        } else {
            setSelectedClient('');
            setSelectedClientId('');
            setSelectedAddress('');
            setselectedtemplate('');
            setselectedtemplateId('');
            setSelectedAddressId('');
            setAddress([]);
        }
    }
    const handleSelecttemplateChange = (selectedOption: SingleValue<IClientOption>) => {
        if (selectedOption) {
            setselectedtemplate(selectedOption.label);
            setselectedtemplateId(selectedOption.value);
        } else {

            setselectedtemplate('');
            setselectedtemplateId('');
        }
    };

    const handleinsuranceChange = (selectedOption: SingleValue<Iinsuranceoption>) => {
        if (selectedOption) {
            setSelectedInsurance(selectedOption.label);
            setSelectedInsuranceId(selectedOption.value);
        } else {
            setSelectedInsurance('');
            setSelectedInsuranceId('');
        }
    };

    const handleAddressChange = (selectedOption: SingleValue<Addresseoption>) => {
        if (selectedOption) {
            setSelectedAddress(selectedOption.label);
            setSelectedAddressId(selectedOption.value);
        } else {
            setSelectedAddress('');
            setSelectedAddressId('');
        }
    };


    const template = async () => {
        try {

            const EncParams = AesEncrypt(JSON.stringify({ selectedClientId }));
            const response = await fetch(webservice + 'api/BulkUpload/template', {
                method: 'POST',
                body: JSON.stringify({ EncParams }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            });
            const result = await response.json();
            const decryptedResult = AesDecrypt(result);
            const res = decryptedResult.map((data: any) => ({
                label: data.file_name,
                value: data.id,
            }));
            settemplateOptions(res);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        template();
    }, [selectedClientId]);



    const get_Address = async () => {
        if (!selectedClientId) {
            // If selectedClientId is an empty string or falsy, return early
            return;
        }

        try {
            const EncParams = AesEncrypt(JSON.stringify({ selectedClientId }));
            const fetchResponse = await fetch(webservice + 'api/address', {
                method: 'POST',
                body: JSON.stringify({ EncParams }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            });

            const data = await fetchResponse.json();
            const data1 = AesDecrypt(data);

            const res = data1.flatMap((item: any) =>
                item.Address.map((address: string) => ({
                    label: address,
                    value: address,
                }))
            );

            setAddress(res);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        get_Address();
    }, [selectedClientId]);



    const deletePatient = async (ids: any[]) => {
        const EncParams = AesEncrypt(JSON.stringify(ids));
        await fetch(webservice + 'api/bulkupload/deletePatientdetails', {
            method: 'POST',
            body: JSON.stringify({ EncParams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });
    };

    const DeleteAuditLogdata = async (data: any[]) => {
        data.forEach(item => {
            item.Userid = localStorage.id;
            item.ParentUserId = localStorage.ParentUserId;
        });
        const EncParams = AesEncrypt(JSON.stringify(data));
        const response = await fetch(webservice + 'api/bulkupload/deleteauditlog', {
            method: 'POST',
            body: JSON.stringify({ EncParams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        }).then(response => response.json());
        return response;
    };

    const DeleteRow = async (rows: any[]) => {
        const ids = rows.map(row => row.id);
        const auditData = rows.map(row => ({
            Username,
            RefId: row.RefId,
            Name: row.Patientname
        }));

        const deleteRow = async () => {
            Swal.fire({
                title: "Insurance Verification Platform",
                text: "Are you sure do you want to delete?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-danger ms-1",
                },
                buttonsStyling: false,
                allowOutsideClick: false
            })
                .then(async function (result) {
                    if (result.isConfirmed) {
                        await deletePatient(ids);
                        const res = await DeleteAuditLogdata(auditData);


                        Swal.fire({
                            icon: "success",
                            title: "Insurance Verification Platform",
                            text: "Clients have been deleted.",
                            customClass: {
                                confirmButton: "btn btn-success",
                            },
                            allowOutsideClick: false
                        });
                        handleReloadTable();
                    }
                });
        };
        deleteRow();
    };


    const [isDownloading, setIsDownloading] = useState(null);

    const handleOpenWordDocument = async (id: any, patient: any) => {
        setIsDownloading(id);
        try {
            const EncParams = AesEncrypt(JSON.stringify({ id: id! })); // Add a non-null assertion here
            const response = await fetch(webservice + 'api/getPatientFile_word', {
                method: 'POST',
                body: JSON.stringify({ EncParams }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            });
            const responseData = await response.json();
            const OutData = AesDecrypt(responseData)

            const docxData = Uint8Array.from(atob(OutData.file), (c) => c.charCodeAt(0));
            const blob = new Blob([docxData], {
                type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            });
            const filename = OutData.filename
            saveAs(blob, filename + '.docx')
            setIsDownloading(null);

            if (!OutData.file) {
                throw new Error('No file data in response');
            }
        } catch (error) {
            console.error('Error downloading file: ', error);
        }
    };
    const handleregenrateDocument = async (row: any) => {
        setLoading(true);
        try {
            // Ensure row.id is defined and not null
            if (!row || !row.id) {
                throw new Error("Invalid row data: id is undefined or null.");
            }

            // Ensure id is treated as a string
            const id1 = [row.id.toString()]; // Create an array with the id as a string

            const EncParams = AesEncrypt(JSON.stringify({ ids: id1 }));
            const fetchResponse = await fetch(webservice + 'api/regenratepatients', {
                method: 'POST',
                body: JSON.stringify({ EncParams }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            });
            const data = await fetchResponse.json();
            const data1 = AesDecrypt(data);

            await axios.get(webservice + 'api/elligibilityapi');
            if (data1 === "S") {
                Swal.fire({
                    icon: "success",
                    title: "Insurance Verification Platform",
                    text: "Patients have been Regenrated.",
                    customClass: {
                        confirmButton: "btn btn-success",
                    },
                    allowOutsideClick: false
                });
                setLoading(false);
                navigate(`/patients`);
            }
        } catch (error) {
            console.error('Error downloading file: ', error);
        }
    };

    interface BootstrapCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
        id: string;
    }

    const BootstrapCheckbox = forwardRef<HTMLInputElement, BootstrapCheckboxProps>(
        ({ id, ...props }, ref) => (
            <div style={{ position: 'relative', left: '10px' }}>
                <input type='checkbox' id={id} ref={ref} {...props} />
            </div>
        )
    );

    const handleChangecheck = useCallback(
        ({ selectedRows }: any) => {
            const selectedEventIds = [];
            selectedRows.forEach((row: any) => {
                selectedEventIds.push(row.id);
            });
            setEventId(selectedRows)
            setEventId1(selectedEventIds.length)
            if (!action.fromUser)
                return;
            selectedRowsPerPage[currentPage] = selectedRows;
        },
        [currentPage, selectedRowsPerPage, action.fromUser]);

    const Columns = [
        { name: 'Patient Name', selector: (row: { Patientname: string }) => row.Patientname, sortable: true },
        { name: 'Client Name', selector: (row: { client_name: string }) => row.client_name, sortable: true },
        { name: 'Template Name', selector: (row: { template: string }) => row.template, sortable: true },
        { name: 'Patient DOB', selector: (row: { PatientDOB: string }) => row.PatientDOB, sortable: true },
        { name: 'Member ID', selector: (row: { Memberid: string }) => row.Memberid ? row.Memberid : 'NA', sortable: true },
        { name: 'Payer ID', selector: (row: { Payor_ID: string }) => row.Payor_ID, sortable: true },
        { name: 'Provider NPI', selector: (row: { Provider_NPI: string }) => row.Provider_NPI, sortable: true },
        { name: 'API Status', selector: (row: { Data_Fetched: string }) => row.Data_Fetched, sortable: true },
        { name: 'Created date', selector: (row: { uploaded_date: string }) => row.uploaded_date, sortable: true },

        {
            name: "View Patient",
            cell: (row: DeleteRowData) => (
                <>
                    {row.Data_Fetched === "Completed" && (
                        <>
                            <FileText
                                size={17}
                                id='editIcon'
                                color='#FFA500'
                                onClick={() => {
                                    navigate(`/patient-eligibility/${row.id}`);
                                }}
                                style={{ cursor: 'pointer', marginRight: '10px' }}
                            />
                            <UncontrolledTooltip placement="top" target="editIcon">
                                View Patient Details
                            </UncontrolledTooltip>
                        </>
                    )}
                    {row.xml_conversion === "C" && (
                        <div onClick={() => handleOpenWordDocument(row.id, row.Patientname)}>
                            {isDownloading === row.id ? (
                                <div className="spinner"></div>
                            ) : (
                                <>
                                    <Download size={20} style={{ cursor: 'pointer' }} id={`downloadIcon-${row.id}`} />
                                    <UncontrolledTooltip placement="top" target={`downloadIcon-${row.id}`}>
                                        Download
                                    </UncontrolledTooltip>
                                </>
                            )}
                        </div>
                    )}
                    {row.Data_Fetched === "Error" && (
                        <span>{row.error_message}</span>
                    )}
                    &nbsp;&nbsp;&nbsp;
                    {/* {row.Data_Fetched !== "Error" && row.regen_status === "Yes" &&(
                <div>
                    <a className="nav-link" onClick={() => handleregenrateDocument(row)}>
                        <LogIn size={20} style={{ cursor: 'pointer' }} id={`regenIcon-${row.id}`} />
                        <UncontrolledTooltip placement="top" target={`regenIcon-${row.id}`}>
                            Regenerate
                        </UncontrolledTooltip>
                    </a>
                </div>
            )} */}
                </>
            ),
        },
    ]

    const [loading, setLoading] = useState(false);
    const customStyles = {
        rows: {
            style: {
                '&:hover': {
                    backgroundColor: '#f0f0f0',

                },
            },
        },
        headCells: {
            style: {
                backgroundColor: '#355876',  // Change background color of headers
                color: 'white',  // Change text color of headers
                fontSize: '16px',  // Adjust font size of headers
            },
        },
    };

    const onSubmit = async (data: IDefaultValues) => {
        if (!selectedClient) {

            Swal.fire({
                icon: 'warning',
                title: 'Insurance Verification Platform',
                text: 'Please Select Client!',
            });
            return;

        }
        if (!selectedtemplate) {

            Swal.fire({
                icon: 'warning',
                title: 'Insurance Verification Platform',
                text: 'Please Select Template!',
            });
            return;

        }
        if (!SelectedAddressname) {

            Swal.fire({
                icon: 'warning',
                title: 'Insurance Verification Platform',
                text: 'Please Select Address!',
            });
            return;

        }

        setLoading(true);
        const dateofbirth = new Date(data.DoB);
        const day = dateofbirth.getDate().toString().padStart(2, "0");
        const month = (dateofbirth.getMonth() + 1).toString().padStart(2, "0");
        const year = dateofbirth.getFullYear();
        const FullDate = month + "/" + day + "/" + year;

        const vendorid = (localStorage.UserType === 'Vendor') ? localStorage.VendorId : "";



        const payload = {
            ClientId: selectedClientId,
            ClientName: selectedClient,
            template: selectedtemplate,
            templateId: selectedtemplateId,
            FirstName: data.firstName,
            MiddleName: data.MiddleName,
            lastName: data.lastName,
            DoB: FullDate,
            ProviderNPI: data.ProviderNPI,
            vendor_id: vendorid,
            // PayorID: data.PayorID,
            Memberid: data.Memberid,
            Insurancename: SelectedInsurancename,
            // InsuranceID: SelectedInsuranceId,
            Address: SelectedAddressname,
            AddressId: SelectedAddressId,
            Subscriber: data.Subscriber,
            Fee_Schedule: data.FeeSchedule,

        }

        const auditLogData = [
            {
                field: "Patient Name",
                value: data.firstName + " " + data.lastName,
            },
            {
                field: "Client Name",
                value: selectedClient,
            },
            {
                field: "Template Name",
                value: selectedtemplate,
            },
            {
                field: "Patient DOB",
                value: FullDate,
            },
            {
                field: "Memberid",
                value: data.Memberid,
            },
            // {
            //     field: "Payer ID",
            //     value: data.PayorID,
            // },
            {
                field: "Provider NPI",
                value: data.ProviderNPI,
            },
            {
                field: "Insurance",
                value: SelectedInsurancename,
            },
            {
                field: "Address",
                value: SelectedAddressname,
            },
            {
                field: "Subscriber",
                value: data.Subscriber,
            },


        ]
        const Username = localStorage.UserName;
        const auditData = { auditLogData, Username }


        const EncParams = AesEncrypt(JSON.stringify({ payload }));

        //  api/bulkupload/addPatientdetails

        const response = await fetch(webservice + 'api/bulkupload/addPatientdetails', {
            method: 'POST',
            body: JSON.stringify({ EncParams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        }).then(response => response.json());
        if (response === "success") {

            const auditLogResponse = await axios.post(webservice + 'api/BulkUpload/AddauditLogdata', auditData, {

                headers: { 'Content-type': 'application/json; charset=UTF-8' }
            });
            Swal.fire({
                title: 'Insurance Verification Platform',
                html: '<span style="color: green;">Patient Added successfully!</span>',
                icon: 'success',
                customClass: {},
                showClass: {
                    popup: 'animate__animated animate__flipInX',
                },
            })
            setLoading(false);
            togglepatient();
            reset();
            setSelectedClient('');
            setSelectedClientId('');
            setselectedtemplate('');
            setselectedtemplateId('');
            handleReloadTable();
            setSelectedAddress('');
            setSelectedAddressId('');
            setAddress([]);
            // const api = await axios.get(webservice + 'api/elligibilityapi');


        }
    };


    const startIndex = (currentPage - 1) * rowsPerPage + 1;
    const endIndex = Math.min(currentPage * rowsPerPage, store.total);
    const CustomLoadingComponent = () => {
        return (
            <div className="">
                {store.no_Data === 'There is no Record Found' ? (
                    <h4>
                        <p>There are no records to display</p>
                    </h4>
                ) : (
                    <div className="custom-loader">
                        <label className="custom-label">Please wait...</label>
                        <div className="custom-loading"></div>
                    </div>
                )}
            </div>
        );
    };

    return (
        <Fragment>

            <Card>

                <CardBody>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <>
                            <div className="d-flex p-1 border" style={{ backgroundColor: "white" }}>
                                <CardBody>
                                    <Row>
                                        {localStorage.UserType === 'MOS' && (
                                            <Col xl={3}>
                                                <Label className="form-label" style={{ fontSize: "110%" }}>
                                                    Select Client <span style={{ color: "red" }}> * </span>
                                                </Label>
                                                <Select
                                                    isClearable
                                                    options={clientOptions}
                                                    classNamePrefix="select"
                                                    placeholder="Client Names"

                                                    className={classnames("form-control", {
                                                        "is-invalid": !!errors.ClientName,
                                                    })}
                                                    styles={{
                                                        control: (styles) => ({
                                                            ...styles,
                                                            width: "100%",
                                                            borderColor: "black",
                                                        }),
                                                        placeholder: (styles) => ({
                                                            ...styles,
                                                            textAlign: "center",
                                                        }),
                                                    }}
                                                    value={selectedClient ? { value: selectedClientId, label: selectedClient } : ""}
                                                    onChange={(selectedOption) => {
                                                        handleSelectClientChange(selectedOption as SingleValue<IClientOption>);
                                                    }}
                                                />
                                                {errors.ClientName && (
                                                    <span style={{ color: "red", fontSize: "12px" }}>
                                                        {errors.ClientName.message} {/* Display the error message */}
                                                    </span>
                                                )}
                                            </Col>
                                        )}

                                        {localStorage.UserType === 'Vendor' && (
                                            <Col xl={3}>
                                                <Label className="form-label" style={{ fontSize: "110%" }}>
                                                    Select Client <span style={{ color: "red" }}> * </span>
                                                </Label>
                                                <Select
                                                    isClearable
                                                    options={vendorbasedclientOptions}
                                                    classNamePrefix="select"
                                                    placeholder="Client Names"

                                                    className={classnames("form-control", {
                                                        "is-invalid": !!errors.ClientName,
                                                    })}
                                                    styles={{
                                                        control: (styles) => ({
                                                            ...styles,
                                                            width: "100%",
                                                            borderColor: "black",
                                                        }),
                                                        placeholder: (styles) => ({
                                                            ...styles,
                                                            textAlign: "center",
                                                        }),
                                                    }}
                                                    value={selectedClient ? { value: selectedClientId, label: selectedClient } : ""}
                                                    onChange={(selectedOption) => {
                                                        handleSelectClientChange(selectedOption as SingleValue<IClientOption>);
                                                    }}
                                                />
                                                {errors.ClientName && (
                                                    <span style={{ color: "red", fontSize: "12px" }}>
                                                        {errors.ClientName.message} {/* Display the error message */}
                                                    </span>
                                                )}
                                            </Col>
                                        )}
                                        <Col xl={3}>
                                            <Label className="form-label" style={{ fontSize: "110%" }}>
                                                Select Address <span style={{ color: "red" }}> * </span>
                                            </Label>
                                            <Select
                                                isClearable
                                                options={Addresseoption}
                                                // value={clientOptions.find((e)=>e.label===selectedClient)}
                                                classNamePrefix="select"
                                                placeholder="Select Address "
                                                //   theme={selectThemeColors}  
                                                className={classnames("form-control", {
                                                    "is-invalid": errors.Address && true,
                                                })}
                                                // {...field}
                                                styles={{
                                                    control: (styles) => ({
                                                        ...styles,
                                                        width: "100%",
                                                        borderColor: "black",
                                                    }),
                                                    placeholder: (styles) => ({
                                                        ...styles,
                                                        textAlign: "center",
                                                    }),
                                                }}
                                                value={SelectedAddressname ? { value: SelectedAddressId, label: SelectedAddressname } : ""}
                                                onChange={(selectedOption) => {
                                                    // field.onChange(selectedOption);
                                                    handleAddressChange(selectedOption as SingleValue<IClientOption>);
                                                }}
                                            />
                                            {errors.Address && (
                                                <span style={{ color: "red", fontSize: "12px" }}>
                                                    {errors.Address.message}
                                                </span>
                                            )}
                                        </Col>
                                        <Col xl={3}>
                                            <Label className="form-label" style={{ fontSize: "110%" }}>
                                                Select Template <span style={{ color: "red" }}> * </span>
                                            </Label>
                                            <Select
                                                isClearable
                                                options={templateOptions}
                                                // value={clientOptions.find((e)=>e.label===selectedClient)}
                                                classNamePrefix="select"
                                                placeholder="Select Template "
                                                //   theme={selectThemeColors}  
                                                className={classnames("form-control", {
                                                    "is-invalid": errors.ClientName && true,
                                                })}
                                                // {...field}
                                                styles={{
                                                    control: (styles) => ({
                                                        ...styles,
                                                        width: "100%",
                                                        borderColor: "black",
                                                    }),
                                                    placeholder: (styles) => ({
                                                        ...styles,
                                                        textAlign: "center",
                                                    }),
                                                }}
                                                value={selectedtemplate ? { value: selectedtemplateId, label: selectedtemplate } : ""}
                                                onChange={(selectedOption) => {
                                                    // field.onChange(selectedOption);
                                                    handleSelecttemplateChange(selectedOption as SingleValue<IClientOption>);
                                                }}

                                            />
                                            {errors.ClientName && (
                                                <span style={{ color: "red", fontSize: "12px" }}>
                                                    {errors.ClientName.message}
                                                </span>
                                            )}
                                        </Col>
                                        <Row style={{ marginTop: "2%" }}>
                                            <Col xl={12}>
                                                <h4>
                                                    <b>Patient Details:</b>
                                                </h4>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={2}>
                                                <Label className="form-label" htmlFor="firstName">
                                                    First Name <span style={{ color: "red" }}>* </span>
                                                </Label>
                                                <Controller
                                                    control={control}
                                                    name="firstName"
                                                    render={({ field }) => (
                                                        <Input
                                                            type="text"
                                                            {...field}
                                                            id="firstName"
                                                            placeholder=""
                                                            invalid={!!errors.firstName}
                                                            onChange={(e) => {
                                                                const inputValue = e.target.value;
                                                                // const onlyText = inputValue.replace(/[^A-Za-z]/gi, "");
                                                                field.onChange(inputValue);
                                                            }}
                                                        />
                                                    )}
                                                    rules={{ required: "First Name is required" }}
                                                />
                                                {errors.firstName && (
                                                    <FormFeedback>
                                                        {errors.firstName.message}
                                                    </FormFeedback>
                                                )}
                                            </Col>

                                            <Col xl={2}>
                                                <Label className="form-label" htmlFor="MiddleName">
                                                    Middle Name
                                                </Label>
                                                <Controller
                                                    control={control}
                                                    name="MiddleName"
                                                    render={({ field }) => (
                                                        <Input
                                                            {...field}
                                                            id="MiddleName"
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                const inputValue = e.target.value;
                                                                const onlyText = inputValue.replace(/[^A-Za-z]/gi, "");
                                                                field.onChange(onlyText);
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Col>

                                            <Col xl={2}>
                                                <Label className="form-label" htmlFor="lastName">
                                                    Last Name <span style={{ color: "red" }}>* </span>
                                                </Label>
                                                <Controller
                                                    name="lastName"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Input
                                                            {...field}
                                                            id="lastName"
                                                            placeholder=""
                                                            invalid={!!errors.lastName}
                                                            onChange={(e) => {
                                                                const inputValue = e.target.value;
                                                                const onlyText = inputValue.replace(/[^A-Za-z]/gi, "");
                                                                field.onChange(onlyText);
                                                            }}
                                                        />
                                                    )}
                                                    rules={{ required: "Last Name is required" }}
                                                />
                                                {errors.lastName && (
                                                    <FormFeedback>
                                                        {errors.lastName.message}
                                                    </FormFeedback>
                                                )}
                                            </Col>
                                            <Col xl={3}>
                                                <Label className="form-label" htmlFor="Memberid">
                                                    Member ID <span style={{ color: "red" }}>* </span>
                                                </Label>
                                                <Controller
                                                    name="Memberid"
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <Input
                                                            // type="text"
                                                            {...field}
                                                            id="Memberid"
                                                            placeholder=""
                                                            invalid={!!errors.Memberid}
                                                            maxLength={15}

                                                        />
                                                    )}
                                                    rules={{ required: "Member ID is required" }}
                                                />
                                                {errors.Memberid && (
                                                    <FormFeedback>
                                                        {errors.Memberid.message}
                                                    </FormFeedback>
                                                )}
                                            </Col>
                                            <Col xl={3}>
                                                <Label className="form-label">Patient Type<span style={{ color: 'red' }}> * </span></Label>
                                                <Controller
                                                    name="Subscriber"
                                                    control={control}
                                                    rules={{ required: "Subscriber is required" }}
                                                    render={({ field }) => (
                                                        <Select<IOption>
                                                            {...field}
                                                            value={SubscriberOptions.find(option => option.value === field.value) || null}
                                                            options={SubscriberOptions}
                                                            getOptionLabel={(option) => option.label}
                                                            getOptionValue={(option) => option.value}
                                                            className={classnames("form-control", {
                                                                "is-invalid": !!errors.Subscriber,
                                                            })}
                                                            classNamePrefix="select"
                                                            onChange={(selectedOption: SingleValue<IOption>) => field.onChange(selectedOption?.value)}
                                                        />
                                                    )}
                                                />
                                                {errors.Subscriber && (
                                                    <FormFeedback>
                                                        {errors.Subscriber.message}
                                                    </FormFeedback>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: "2%" }}>
                                            {/* <Col xl={3}>
                                                <Label className="form-label" htmlFor="PayorID">
                                                    Payer ID <span style={{ color: "red" }}>* </span>
                                                </Label>
                                                <Controller
                                                    name="PayorID"
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <Input
                                                            // type=""
                                                            {...field}
                                                            id="PayorID"
                                                            placeholder=""
                                                            invalid={!!errors.PayorID}
                                                            maxLength={10}

                                                        />
                                                    )}
                                                    rules={{ required: "Payor ID is required" }}
                                                />
                                                {errors.PayorID && (
                                                    <FormFeedback>
                                                        {errors.PayorID.message}
                                                    </FormFeedback>
                                                )}
                                            </Col> */}
                                            <Col xl={3}>
                                                <Label className="form-label" htmlFor="DoB">
                                                    Date of Birth <span style={{ color: "red" }}>* </span>
                                                </Label>
                                                <Controller
                                                    control={control}
                                                    name="DoB"
                                                    render={({ field }) => (
                                                        <Flatpickr
                                                            {...field}
                                                            options={{
                                                                dateFormat: "m/d/Y",
                                                                maxDate: "today",
                                                            }}
                                                            className={classnames("form-control", {
                                                                "is-invalid": !!errors.DoB,
                                                            })}
                                                        />
                                                    )}
                                                    rules={{ required: "Date of Birth is required" }}
                                                />
                                                {errors.DoB && (
                                                    <span style={{ color: "red", fontSize: "12px" }}>
                                                        {errors.DoB.message}
                                                    </span>
                                                )}
                                            </Col>
                                            <Col xl={3}>
                                                <Label className="form-label" htmlFor="ProviderNPI">
                                                    Provider NPI ID <span style={{ color: "red" }}>* </span>
                                                </Label>
                                                <Controller
                                                    name="ProviderNPI"
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <Input
                                                            // type="number"
                                                            {...field}
                                                            id="ProviderNPI"
                                                            placeholder=""
                                                            invalid={!!errors.ProviderNPI}
                                                            maxLength={10}

                                                        />
                                                    )}
                                                    rules={{ required: "Provider NPI ID is required" }}
                                                />
                                                {errors.ProviderNPI && (
                                                    <FormFeedback>
                                                        {errors.ProviderNPI.message}
                                                    </FormFeedback>
                                                )}
                                            </Col>
                                            <Col xl={3}>
                                                <Label className="form-label">
                                                    Select Insurance<span style={{ color: 'red' }}> * </span>
                                                </Label>
                                                <Controller
                                                    name="Insurancename"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            isClearable
                                                            {...field}
                                                            options={insuranceoption}
                                                            classNamePrefix="select"
                                                            className={classnames("form-control", {
                                                                "is-invalid": !!errors.Insurancename,
                                                            })}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption);
                                                                handleinsuranceChange(selectedOption as SingleValue<Iinsuranceoption>);
                                                            }}
                                                        />
                                                    )}
                                                    rules={{ required: "Insurance is required" }}

                                                />
                                                {errors.Insurancename && (
                                                    <FormFeedback className="text-danger">
                                                        {errors.Insurancename.message}
                                                    </FormFeedback>
                                                )}
                                            </Col>
                                            <Col xl={3}>
                                                <Label className="form-label" htmlFor="FeeSchedule">
                                                    Fee Schedule
                                                </Label>
                                                <Controller
                                                    control={control}
                                                    name="FeeSchedule"
                                                    render={({ field }) => (
                                                        <Input
                                                            {...field}
                                                            id="FeeSchedule"
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                const inputValue = e.target.value;
                                                                const onlyText = inputValue.replace(/[^A-Za-z]/gi, "");
                                                                field.onChange(onlyText);
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Col>

                                            <Row style={{ marginTop: "3%" }}>
                                                <Col xl={2}>
                                                    <Button
                                                        type="submit"
                                                        color="success"
                                                        className="btn-icon d-flex align-items-center justify-content-center"
                                                    >
                                                        Save Patient
                                                    </Button>
                                                </Col>
                                                <Col xl={2}>
                                                    <Button
                                                        className="btn-icon d-flex align-items-center justify-content-center"
                                                        color="danger"
                                                        onClick={() => {
                                                            setSelectedClient("");
                                                            setSelectedClientId("");
                                                            reset();
                                                        }}
                                                    >
                                                        Clear
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Row>
                                    </Row>
                                </CardBody>
                            </div>
                        </>
                    </form>
                    <CardBody>
                        <div className="table-responsive">
                            <div className="dataTables_wrapper">
                                <DataTable
                                    subHeader={true}
                                    responsive={true}
                                    onSort={handleSort}
                                    data={dataToRender()}
                                    columns={Columns}
                                    paginationServer
                                    customStyles={customStyles}
                                    paginationPerPage={rowsPerPage}
                                    paginationDefaultPage={currentPage}
                                    selectableRows
                                    selectableRowsHighlight
                                    selectableRowsComponent={BootstrapCheckbox as unknown as React.ReactNode}
                                    onSelectedRowsChange={handleChangecheck}
                                    paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                                    noDataComponent={<CustomLoadingComponent />}
                                    paginationComponent={CustomPagination}
                                    subHeaderComponent={
                                        <CustomHeader
                                            value={value}
                                            rowsPerPage={rowsPerPage}
                                            handleFilter={handleFilter}
                                            handlePerPage={handlePerPage}
                                        />
                                    }
                                />
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div style={{ paddingLeft: '2%' }}>
                                        {store.total && (
                                            <span><strong>{`${startIndex} to ${endIndex} of ${store.total} entries`}</strong></span>
                                        )}
                                    </div>
                                    <div>
                                        <CustomPagination />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </CardBody>
            </Card>
            {loading && (
                <div >
                    <div className="loading-overlay">
                        <div id="page">
                            <div id="container">
                                <div id="ring"></div>
                                <div id="ring"></div>
                                <div id="ring"></div>
                                <div id="ring"></div>
                                <div id="h3" style={{ color: "black" }}>Loading...</div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div>
                {eventId1 > 0 && menuActions.includes('delete') && (
                    <div className="customizer-links open">
                        <Nav className=" flex-column nav-pills">
                            <a className="nav-link" onClick={() => DeleteRow(eventId)}>
                                <span>Delete</span>
                                <Trash2 className="trash-icon" size={20} />
                            </a>
                        </Nav>
                    </div>
                )}
            </div>
        </Fragment>
    );
}

export default Bulkupload;
